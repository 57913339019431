import React, { useEffect, useState } from 'react';
import { allAnnonce } from "../../Service/Annonce/Annonceget";
import CustomModal from "../../Commons/Component/CustomModal";
import EditAnnonce from "../../Commons/Component/Annonce/EditAnnonce";
import { getUserRole } from "../../utils/authUtils";
import { getUserName } from "../../utils/authUtils";
import CardView from "../../Commons/Component/Cards/CardView";
import AddAnnonce from "../../Commons/Component/Annonce/AddAnnonce";
import Pagination from "../../Commons/Component/Pagination/paginationTableau";
import DeleteAnnonceButton from "../../Commons/Component/Annonce/DeleteAnnonce";
import CountryFilter from "../../Commons/Component/Filtre/CountryFilterObjet";
import { updateLiveStatus } from '../../Service/Annonce/AnnonceLiveUptade'; // Assure-toi que cette fonction est correctement importée

const userData = getUserName();
const userRole = getUserRole();

function AnnonceTable() {
    const [annonces, setAnnonces] = useState([]);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedAnnonce, setSelectedAnnonce] = useState(null);
    const [modalAction, setModalAction] = useState('');
    const [dataErrors, setDataErrors] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [annoncesPerPage] = useState(4);
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');

    useEffect(() => {
        allAnnonce()
            .then(response => {
                if (response && response.annonces && Array.isArray(response.annonces)) {
                    setAnnonces(response.annonces);
                    const uniqueCountries = [...new Set(response.annonces.map(annonce => annonce.country && annonce.country.name))];
                    setCountries(uniqueCountries.filter(country => country));
                } else if (Array.isArray(response)) {
                    setAnnonces(response);
                    const uniqueCountries = [...new Set(response.map(annonce => annonce.country && annonce.country.name))];
                    setCountries(uniqueCountries.filter(country => country));
                } else {
                    setError("Les données ne sont pas dans le format attendu.");
                }
            })
            .catch(err => {
                setError(err.message);
            });
    }, []);

    const openModal = (id, action) => {
        const annonce = annonces.find((a) => a.id === id);
        if (annonce) {
            setSelectedAnnonce(annonce);
            setModalAction(action);
            setIsModalOpen(true);
        }
    };

    const openAddModal = () => {
        setIsModalOpen(true);
        setSelectedAnnonce(null);
        setModalAction('add');
    };

    const approveAnnonce = async (annonceId) => {
        try {
            const result = await updateLiveStatus(annonceId);
            console.log(`Annonce ${annonceId} approuvée:`, result);
            const updatedAnnonces = annonces.map(annonce =>
                annonce.id === annonceId ? { ...annonce, live: "1" } : annonce
            );
            setAnnonces(updatedAnnonces);
        } catch (error) {
            console.error('Erreur lors de l\'approbation de l\'annonce:', error);
        }
    };

    const checkIfApprovable = (annonce) => {
        const now = new Date();
        const endDate = new Date(annonce.dateEnd);
        return annonce.live === "0" && endDate >= now;
    };

    if (error) {
        return <div>Erreur : {error}</div>;
    }

    const prepareDataForPagination = (data) => {
        if (Array.isArray(data)) {
            return data;
        } else if (typeof data === 'object') {
            return Object.values(data).flat();
        }
        return [];
    };

    const allAnnoncesPaginated = prepareDataForPagination(annonces);

    const indexOfLastAnnonce = currentPage * annoncesPerPage;
    const indexOfFirstAnnonce = indexOfLastAnnonce - annoncesPerPage;
    let currentAnnonces = allAnnoncesPaginated.slice(indexOfFirstAnnonce, indexOfLastAnnonce);

    if (selectedCountry) {
        currentAnnonces = currentAnnonces.filter(annonce => annonce.country && annonce.country.name === selectedCountry);
    }

    const totalPages = Math.ceil(allAnnoncesPaginated.length / annoncesPerPage);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
            <div className="p-4">
                <h1 className="text-2xl font-bold mb-4">Annonces</h1>
                <div className="flex justify-end mb-4">
                    <button onClick={openAddModal} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:bg-blue-600">
                        Add Annonce
                    </button>
                </div>
                <CountryFilter
                    countries={countries}
                    selectedCountry={selectedCountry}
                    setSelectedCountry={setSelectedCountry}
                />
                <div className="overflow-x-auto">
                    <table className="table-auto w-full whitespace-nowrap text-left divide-y divide-gray-200">
                        <thead>
                        <tr>
                            <th className="font-medium text-gray-700 tracking-wider px-4 py-2">ID</th>
                            <th className="font-medium text-gray-700 tracking-wider px-4 py-2">Présentation</th>
                            <th className="font-medium text-gray-700 tracking-wider px-4 py-2">Image</th>
                            <th className="font-medium text-gray-700 tracking-wider px-4 py-2">Date</th>
                            <th className="font-medium text-gray-700 tracking-wider px-4 py-2">Catégorie</th>
                            <th className="font-medium text-gray-700 tracking-wider px-4 py-2">Title</th>
                            {userRole !== 'user' && userRole !== 'admin' && (
                                <th className="font-medium text-gray-700 tracking-wider px-4 py-2">Country</th>
                            )}
                            <th className="font-medium text-gray-700 tracking-wider px-4 py-2">User</th>
                            <th className="font-medium text-gray-700 tracking-wider px-4 py-2">Actions</th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                        {currentAnnonces.map((annonce, index) => (
                            <tr key={index} className="hover:bg-gray-100">
                                <td className="px-4 py-2">{annonce.id || '-'}</td>
                                <td className="px-4 py-2">{annonce.presentation || '-'}</td>
                                <td className="px-4 py-2">
                                    {annonce.image ? (
                                        <img src={decodeURIComponent(annonce.image)} alt="annonce" className="w-20 h-20 object-cover" />
                                    ) : (
                                        '-'
                                    )}
                                </td>
                                <td className="px-4 py-2">{annonce.date || '-'}</td>
                                <td className="px-4 py-2">{annonce.categorie || '-'}</td>
                                <td className="px-4 py-2">{annonce.title || '-'}</td>
                                {userRole !== 'user' && userRole !== 'admin' && (
                                    <td className="px-4 py-2">{annonce.country ? annonce.country.name : '-'}</td>
                                )}
                                <td className="px-4 py-2">{annonce.user ? annonce.user.firstName : '-'}</td>
                                <td className="px-4 py-2">
                                    <div className="flex gap-2 justify-center items-center">
                                        <button onClick={() => openModal(annonce.id, 'view')} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:bg-blue-600">
                                            View
                                        </button>
                                        <button onClick={() => openModal(annonce.id, 'edit')} className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 focus:bg-green-600">
                                            Edit
                                        </button>
                                        <button onClick={() => openModal(annonce.id, 'delete')} className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:bg-red-600">
                                            Delete
                                        </button>
                                        {checkIfApprovable(annonce) && (
                                            <button onClick={() => approveAnnonce(annonce.id)} className="px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-600 focus:bg-purple-600">
                                                Approuver
                                            </button>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                {totalPages > 1 && (
                    <Pagination usersPerPage={annoncesPerPage} totalUsers={allAnnoncesPaginated.length} paginate={paginate} />
                )}
            </div>
            <CustomModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                title={selectedAnnonce ? `Annonce: ${selectedAnnonce.title}` : ''}
            >
                {modalAction === 'add' && <AddAnnonce />}
                {modalAction === 'edit' && selectedAnnonce && <EditAnnonce annonce={selectedAnnonce} dataErrors={dataErrors} />}
                {modalAction === 'view' && selectedAnnonce && <CardView annonce={selectedAnnonce} dataErrors={dataErrors} />}
                {modalAction === 'delete' && selectedAnnonce && <DeleteAnnonceButton id={selectedAnnonce.id} />}
            </CustomModal>
        </>
    );
}

export default AnnonceTable;
